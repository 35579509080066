import React from 'react';
import PageCsv from '../components/Csv/PageCsvMCQ';

const SPREADSHEET_MCQ = '19_OzF-irYOmpKFhbEDiif-dea7nQLlKToFW3QbY0RzQ';
const SHEET_ID = '2021.1';
const TITLE = 'MCQ 2021.1';
const RANGE = 'A2:Z';

export default function Page () {
  const RANGE_COMPLETE = SHEET_ID ? `${SHEET_ID}!${RANGE}` : RANGE;
  return (
    <PageCsv
      title={TITLE}
      spreadsheetId={SPREADSHEET_MCQ}
      sheetId={SHEET_ID}
      range={RANGE_COMPLETE}
    />
  )

}
